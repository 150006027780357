<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-xl-6 d-flex mt-2">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase p-2 mt-0 mb-3 text-center bg-light">
                        Instruction Form - Order {{ orderProductColor.order.order_number }}
                    </h5>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Designer Id :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="orderProductColor.assignee && orderProductColor.assignee.designer_id"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Order Number :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="orderProductColor.order.order_number"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Order Date :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="orderProductColor.order.created_at | formatDateOnly"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Order Design Date :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="instruction.created_at | formatDateOnly"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Scan Date :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Ship Date :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="shippingDate | formatDateOnly"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Number of designs for this order :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="orderProductColor.order.orderProductColor.length"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Number of orthotics for this design :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="orderProductColor.quantity"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Customer Name :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="`${orderProductColor.order.customer.first_name} ${orderProductColor.order.customer.last_name}`"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Gender :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="genderText"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Model Name :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="orderProductColor.productColor.product.name"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Last Customer's Order Number :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="orderProductColor.order.customer.previous_order_number"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Shell Material :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                v-model="instruction.shell_material"
                                class="form-control form-control-sm bg-light border-0"
                                type="text"
                            />
                        </div>
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        Shell Material Note :
                    </h4>
                    <textarea
                        id="contentTitle"
                        v-model="instruction.shell_material_note"
                        class="form-control bg-light border-0"
                        rows="2"
                    />
                </div>
            </div>

            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase pb-2 mt-0">
                        Shoe Information
                    </h5>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Type :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Shoe Brand :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Shoe Size - US :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                v-model="orderProductColor.size"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Shoe Size - EU :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                v-model="euSize"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Removable Inserts :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="orderProductColor.removable_inserts | startCase"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Photos Available :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                :value="
                                    orderProductColor.images.length
                                        ? `Yes (${orderProductColor.images.length})`
                                        : 'No'
                                "
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase pb-2 mt-0">
                        Shell Information
                    </h5>
                    <div class="row">
                        <h4 class="font-13 text-muted text-uppercase col-6">
                            Heel Thickness (mm) :
                        </h4>
                        <div class="form-group mb-input col-6">
                            <input
                                v-model="instruction.heel_thickness"
                                class="form-control form-control-sm bg-light border-0"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="font-13 text-muted text-uppercase col-6">
                            Forefoot thickness (mm) :
                        </h4>
                        <div class="form-group mb-input col-6">
                            <input
                                v-model="instruction.forefoot_thickness"
                                class="form-control form-control-sm bg-light border-0"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="font-13 text-muted text-uppercase col-6">
                            Width :
                        </h4>
                        <div class="form-group mb-input col-6">
                            <b-form-select
                                v-model="instruction.width"
                                :options="widthOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase pb-2 mt-0">
                        Measurments (mm)
                    </h5>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Heel Length :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                v-model="instruction.heel_length"
                                class="form-control form-control-sm bg-light border-0"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Forefoot Width :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                v-model="instruction.forefoot_width"
                                class="form-control form-control-sm bg-light border-0"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Heel Width :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                v-model="instruction.heel_width"
                                class="form-control form-control-sm bg-light border-0"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Arch Height :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <input
                                v-model="instruction.arch_height"
                                class="form-control form-control-sm bg-light border-0"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            New Grinding :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.new_grinding"
                                :options="newGrindingOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Big Arch - Left :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.big_arch_left"
                                :options="archOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Big Arch - Right :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.big_arch_right"
                                :options="archOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Small Arch - Left :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.small_arch_left"
                                :options="archOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Small Arch - Right :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.small_arch_right"
                                :options="archOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Draft :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.draft"
                                :options="draftOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Arch Extra Width :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.arch_extra_width"
                                :options="confirmOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Extra Draft To Arch :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.arch_extra_draft"
                                :options="confirmOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase pb-2 mt-0">
                        Finishing Instructions
                    </h5>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Type MB :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_type_mb"
                                :options="sideOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Type HH :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_type_hh"
                                :options="sideOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Type HP :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_type_hp"
                                :options="sideOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Type 1M :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_type_1m"
                                :options="sideOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Type Arch Pad :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_type_arch_pad"
                                :options="sideOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Type Met Domes :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_type_met_domes"
                                :options="sideOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Type Reverse Mortons :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_type_reverse_mortons"
                                :options="sideOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Type Mortons Extension :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_type_mortons_extension"
                                :options="sideOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material MB :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_mb"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material HH :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_hh"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material HP1 :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_hp"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material HP2 :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_hp2"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material HP3 :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_hp3"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material 1M :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_1m"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material Arch Pad :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_arch_pad"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material Met Domes :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_met_domes"
                                :options="metDomesMaterialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material Reverse Mortons :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_reverse_mortons"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Padding Material Mortons Extension :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.padding_material_mortons_extension"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase pb-2 mt-0">
                        Midlayer & Top Cover
                    </h5>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Midlayer Length :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.midlayer_length"
                                :options="midlayerLengthOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Midlayer Material :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.midlayer_material"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Top Cover Length :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.top_cover_length"
                                :options="lengthOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Top Cover Material :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.top_cover_material"
                                :options="coverMaterialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Extra Midlayer :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.extra_midlayer"
                                :options="materialOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Extra Midlayer Length :
                        </h4>
                        <div class="col-6 form-group mb-input">
                            <b-form-select
                                v-model="instruction.extra_midlayer_length"
                                :options="lengthOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h4 class="col-6 font-13 text-muted text-uppercase">
                            Product Color :
                        </h4>
                        <div class="col-6  form-group d-flex mb-input">
                            <span
                                :style="{
                                    'background-color': orderProductColor.productColor.hex,
                                }"
                                class="colour-block mr-1"
                                :class="{ 'is-white': orderProductColor.productColor.hex === '#FFFFFF' }"
                            />

                            <input
                                v-model="orderProductColor.productColor.name"
                                class="form-control form-control-sm"
                                type="text"
                                disabled
                            />
                        </div>
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        Designer Additinal Info :
                    </h4>
                    <textarea
                        id="contentTitle"
                        v-model="instruction.designer_info"
                        class="form-control bg-light border-0"
                        rows="3"
                    />
                    <h4 class="font-13 text-muted text-uppercase">
                        Lab's Remark :
                    </h4>
                    <textarea
                        id="contentTitle"
                        v-model="instruction.labs_remark"
                        class="form-control bg-light border-0"
                        rows="3"
                    />
                    <h4 class="font-13 text-muted text-uppercase">
                        Extra Customer Comments :
                    </h4>
                    <textarea
                        id="contentTitle"
                        v-model="instruction.customer_info"
                        class="form-control bg-light border-0"
                        rows="3"
                    />
                </div>
            </div>

            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase pb-2 mt-0">
                        Production
                    </h5>
                    <div class="radio radio-primary ml-1">
                        <input
                            id="showVersionAlert"
                            v-model="instruction.production"
                            type="radio"
                            name="customRadio"
                            value="PH"
                        />
                        <label for="showVersionAlert">
                            PH
                        </label>
                    </div>
                    <div class="radio radio-primary mt-1 ml-1">
                        <input
                            id="refreshAutomatically"
                            v-model="instruction.production"
                            type="radio"
                            name="customRadio"
                            value="ILS"
                        />
                        <label for="refreshAutomatically">
                            ILS
                        </label>
                    </div>
                    <div
                        v-if="$v.instruction.production.$error"
                        class="text-danger mt-2"
                    >
                        Production field is required.
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-xl-6 d-flex mb-n5">
                <div class="card-box flex-fill">
                    <button
                        class="btn btn-success waves-effect waves-light btn-block"
                        type="button"
                        @click="saveInstruction"
                    >
                        Save Instruction Form
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Factory Data Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        return {
            instruction: {},
            orderProductColor: {
                account: {},
                order: {
                    customer: {},
                    orderProductColor: []
                },
                productColor: {
                    product: {}
                },
                history: [],
                images: []
            },
            sizes: [],
            widthOptions: ['', 'Wide', 'Slim'],
            lengthOptions: ['', '3/4', 'Sulcus', 'FL'],
            midlayerLengthOptions: ['', '3/4', 'Sulcus', 'FL', 'No midlayer'],
            archOptions: ['', 0, 3, 6, 9],
            draftOptions: ['Std', 'Extra'],
            confirmOptions: ['', 'Yes'],
            newGrindingOptions: ['', 'Yes', 'No'],
            sideOptions: ['', 'L', 'R', 'B'],
            materialOptions: [
                '',
                '1.6P',
                '3.2P',
                '1mm EVA SH 50',
                '2mm EVA SH 30',
                '3mm EVA SH 30',
                '3mm EVA SH 50',
                '4mm EVA SH 30',
                '2mm Lunatec motion'
            ],
            metDomesMaterialOptions: [
                '',
                'Small',
                'Medium',
                'Large',
                'Small - 10mm past shell',
                'Medium - 10mm past shell',
                'Large - 10mm past shell'
            ],
            coverMaterialOptions: [
                '',
                'S BLK',
                'S WHT',
                'S TUQ',
                'S GREEN',
                'S YEL',
                'S ORANGE',
                'SEC BROWN',
                '2mm Black neoprene MP6',
                '2mm Blue neoprene MP7',
                '2mm Black neoprene MP8',
                '2mm Blue neoprene MP9',
                '1mm SH 50'
            ],
            productionStatuses: [
                'PRODUCTION PROCESS WITH SHIPMENT',
                'ENTERING PRODUCTION LINE'
            ]
        };
    },

    validations: {
        instruction: {
            production: {
                required
            }
        }
    },

    computed: {
        genderText() {
            const { genderIdentifier } = this.orderProductColor.account;

            if (genderIdentifier === 'gender_men') {
                return 'Men';
            }

            if (genderIdentifier === 'gender_women') {
                return 'Women';
            }

            return null;
        },

        euSize() {
            const size = this.sizes.find(
                size => Number(size.size) === Number(this.orderProductColor.size)
            );

            if (!size) {
                return '-';
            }

            if (this.genderText === 'Men') {
                return size.eu_size_men;
            } else {
                return size.eu_size_women;
            }
        },

        shippingDate() {
            const shippedStatus = this.orderProductColor.history.find(
                status => status.status === 'SHIP_TO_CUSTOMER'
            );

            if (!shippedStatus) {
                return '';
            }

            return shippedStatus.created_at;
        }
    },

    async mounted() {
        this.orderProductColorId = this.$route.params.id;
        this.fetchOrderProductColor();
        this.sizes = await this.getSizes();
    },

    methods: {
        ...mapActions({
            getOrderProductColor: 'orderProductColors/show',
            getSizes: 'productSizes/index',
            createOrUpdateInstruction: 'orderProductColors/createOrUpdateInstruction'
        }),

        async fetchOrderProductColor() {
            try {
                this.orderProductColor = await this.getOrderProductColor(
                    this.orderProductColorId
                );

                if (this.orderProductColor.instruction) {
                    this.instruction = this.orderProductColor.instruction;
                }
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async saveInstruction() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if (this.productionStatuses.includes(this.orderProductColor.status)) {
                const confirmed = await this.$confirmationModal(
                    `Are you sure you want to change this item's data?`
                );

                if (!confirmed) {
                    return;
                }
            }

            try {
                this.instruction = await this.createOrUpdateInstruction({
                    id: this.orderProductColor.id,
                    body: this.instruction
                });

                window.close();
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.mb-input {
    margin-bottom: 14px;
}

.colour-block {
    height: 31px;
    width: 31px;
    flex-shrink: 0;
    border-radius: 0.2rem;

    &.is-white {
        border: 1px solid #ced4da;
    }
}
</style>
